export const useQueryStore = defineStore('LDX-QUERY', () => {
  interface QueryData {
    /**
     * 渠道id
     *
     * 默认 10000
     */
    openid: string
    /**
     * 生成的下载链接
     *
     * final 没有 跳首页
     *
     * final = shop、cloud （跳助手exe的shop和cloud页面）
     *
     * final = 包名 跳助手exe的游戏详情
     *
     */
    final: string | undefined
  }
  const queryData = ref<QueryData>({
    openid: '10000',
    final: undefined,
  })

  function initQueryData() {
    const route = useRoute()
    // 如果是 n 参数，则直接赋值给 openid
    loEntries(route.query as Record<string, string>).forEach(([key, value]) => {
      if (key === 'n')
        queryData.value.openid = value

      else if (loHas(queryData.value, key))
        queryData.value[key as keyof QueryData] = value
    })

    if (!isProd()) {
      clientLog.log('URL QUERY => ', queryData.value)
    }
  }

  return {
    queryData,
    initQueryData,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useQueryStore, import.meta.hot))

import type { LocaleObject } from '@nuxtjs/i18n'
import type { SupportLocale } from '~/config/i18n'

export const useLanguageStore = defineStore('LDX-LANGUAGE', () => {
  interface LangConfig extends Pick<Partial<LocaleObject>, 'domain' | 'name' | 'code'> {
    title?: string
  }

  /** 用于阻塞render 等待正确数据进入后再渲染 */
  const languageLoading = createControlledPromise()
  /** 顶部右侧选择语言 */
  const languageList = shallowRef(initLanguageList())

  function initLanguageList(): LangConfig[] {
    const { $i18n: { locale, locales } } = useNuxtApp()
    return locales.value.filter(item => item.code !== locale.value).map(({ code, domain, name }) => ({ code, domain, name }))
  }

  /**
   * 设置顶部右侧选择语言
   * @param subPathMap - { [地区]: url详细地址 }
   * @param titleMap - { [地区]: a标签的 title 名 }
   */
  function setLanguage(subPathMap?: Partial<Record<SupportLocale, string>>, titleMap?: Partial<Record<SupportLocale, string>>) {
    const cloneLangs = loCloneDeep(initLanguageList())
    languageList.value = cloneLangs.filter((i) => {
      if (!subPathMap?.[i.code as SupportLocale])
        return false

      i.domain += subPathMap[i.code as SupportLocale]!
      i.title = titleMap?.[i.code as SupportLocale]
      return true
    })
  }

  function resetLanguage() {
    languageList.value = initLanguageList()
  }

  return {
    languageLoading,

    languageList,
    setLanguage,
    resetLanguage,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useLanguageStore, import.meta.hot))

<script setup lang="ts">
const { conf } = useLayout()

const { getXLink } = useDownload()
const link = getXLink()

const { toIndex, toSearch } = useRouterToPage()
const mobSideBarVisible = ref(false)
</script>

<template>
  <header
    id="header" class="fixed left-0 right-0 top-0 z-99 flex items-center justify-between c-#fff transition-all-100" b="b solid #ffffff1a" px="20px md:40px"
    :class="conf.head.bg"
  >
    <div class="h-full flex items-center gap-40px">
      <NuxtLink :to="toIndex({ hasReturnUrl: true })" aria-label="home page">
        <Icon name="LogoName" class="aspect-ratio-245/40 h-fit c-#fff" w="148px md:162px" />
      </NuxtLink>
      <!-- 导航菜单 -->
      <LayoutHeaderNav class="lt-md:hidden" />
    </div>

    <div class="flex items-center gap-24px lt-md:hidden">
      <LayoutHeaderSearch v-if="conf.head.search" />

      <!-- 语言切换 -->
      <LayoutHeaderLanguage v-if="conf.head.language" />

      <ClientOnly>
        <a v-if="conf.head.download" v-track="{ id: 'web-layout-download', click: { value: { page: currentRouteName() } } }" class="ld-button text-14px ld-button-purple" :href="link">
          {{ $t('layout.download_ld_x') }}
        </a>

        <template #fallback>
          <a v-track="{ id: 'web-layout-download', click: { value: { page: currentRouteName() } } }" class="ld-button text-14px ld-button-purple" :href="link">
            {{ $t('layout.download_ld_x') }}
          </a>
        </template>
      </ClientOnly>
    </div>

    <div class="flex gap-16px text-16px md:hidden">
      <div class="size-32px flex-center rd-4px bg-#ffffff1a" @click="toSearch('')">
        <Icon name="Search" class="c-#fff c-op-90" />
      </div>

      <div class="size-32px flex-center rd-4px bg-#ffffff1a" @click="mobSideBarVisible = !mobSideBarVisible">
        <Icon name="NavMob" class="c-#fff c-op-90" />
      </div>

      <LayoutHeaderMobSidebar v-model="mobSideBarVisible" />
    </div>
  </header>
</template>

<script setup lang="ts">
interface Props {
  mode: 'dark' | 'light'
}
const props = defineProps<Props>()

interface FollowIcon {
  alt: string
  href: string
  style?: Record<string, string>
}

const { t } = useI18n()

const spriteIconStyle = ref()

const followIcons = computed(() =>
  ['layout.foot_follow1', 'layout.foot_follow2', 'layout.foot_follow3', 'layout.foot_follow4'].map((key) => {
    if (t(key)) {
      const [icon, href] = t(key).split('|')
      const style = spriteIconStyle.value?.[icon as MediaName]

      return style ? { href, style, alt: `go to ${icon}` } : undefined
    }
    return undefined
  }).filter(Boolean) as FollowIcon[],
)

const changeSpriteIcon = useDebounceFn(() => {
  spriteIconStyle.value = getSpriteIconStyle(window.innerWidth <= 768 ? 40 : 32, props.mode)
}, 100)

watch(() => props.mode, () => changeSpriteIcon())

onMounted(() => {
  changeSpriteIcon()
  window.addEventListener('resize', changeSpriteIcon)
})
</script>

<template>
  <div class="flex gap-16px">
    <ClientOnly>
      <template v-for="icon in followIcons" :key="icon">
        <a :href="icon.href" :aria-label="icon.alt" target="_blank" class="group relative of-hidden rd-full">
          <div class="transform-gpu transition-transform-300 group-hover:scale-110" size="40px md:32px">
            <img
              :style="icon.style" src="/support.webp" :alt="icon.alt" loading="lazy"
              class="absolute top-0 float-left aspect-ratio-269/14" h="40px md:32px"
            >
          </div>
        </a>
      </template>

      <template #fallback>
        <div v-for="i in 4" :key="i" size="40px md:32px" class="rd-full bg-#000 bg-op-10" />
      </template>
    </ClientOnly>
  </div>
</template>

<style lang="scss" scoped></style>

import type { DeepPartial } from 'unocss'

export function useLayout() {
  /** layout相关的配置 */
  const conf = useState<LayoutConf>('layoutConf', () => initConf())

  /** 是否镜像阅读 */
  const isRtl = computed(() => {
    const { locale } = useNuxtApp().$i18n
    return locale.value === 'ar'
  })

  const { y: windowScrollY } = import.meta.client
    ? useScroll(window, { behavior: 'smooth' })
    : { y: ref(0) }

  /** 滚动到顶部 */
  function windowScrollTo(offset: number = 0) {
    windowScrollY.value = offset
  }

  // 默认配置
  function initConf(): LayoutConf {
    return {
      mode: 'light',
      head: {
        bg: 'bg-[var(--bg-color)]',
        language: true,
        download: true,
        search: true,
      },
      footBar: {
        fixed: false,
        fixeding: false,
        visible: false,
      },
      sidebar: {
        fixed: true,
        share: false,
        visible: false,
        bottomOffset: 0,
      },
    }
  }

  function setConf(newConf: DeepPartial<LayoutConf>) {
    loMerge(conf.value, newConf)
  }

  // 每次进入下一个页面都会重置配置
  function resetConf() {
    conf.value = initConf()
  }

  /**
   * 超过一屏自动出现回到顶部按钮 sidebar.visible = true
   *
   * 可以定制化其他属性修改
   */
  function autoSideBar(dynamicConf?: (height: number, scrollY: number) => DeepPartial<LayoutConf>) {
    const { height } = useWindowSize()
    watchThrottled(windowScrollY, (scrollY) => {
      setConf({
        ...dynamicConf?.(height.value, scrollY),

        sidebar: {
          visible: scrollY > (height.value * 0.9),
        },
      })
    }, { throttle: 300, immediate: true })
  }

  return {
    isRtl,
    windowScrollY,
    windowScrollTo,

    conf,
    setConf,
    resetConf,

    autoSideBar,
  }
}

interface LayoutConf {
  mode: 'dark' | 'light'
  head: {
    bg: string
    language: boolean
    download: boolean
    search: boolean
  }
  footBar: {
    /** 固定屏幕 */
    fixed: boolean
    /** 是否正在 */
    fixeding: boolean
    /** 固定屏幕 */
    visible: boolean
  }
  /** 侧边栏 */
  sidebar: {
    /** 固定屏幕 */
    fixed: boolean
    /** 携带分享 */
    share: boolean
    /** 是否出现 */
    visible: boolean
    /**
      距离底部间距 -- 解决游戏详情重叠问题
     */
    bottomOffset: number
  }
}

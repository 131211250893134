<script setup lang="ts">
interface Props {
  visible: boolean
  fixedMode?: boolean
}

defineProps<Props>()

const { getXLink } = useDownload()
const link = getXLink()

const { isWindows } = useDevice()
</script>

<template>
  <div v-if="visible" class="of-hidden bg-purple-5" :class="fixedMode ? 'fixed z-10 bottom-0 w-full shadow-md' : 'w-full relative'">
    <Icon
      name="FootTopBarBg" class="pointer-events-none absolute aspect-ratio-115/109 h-auto c-#fff c-op-5 flip-rtl"
      md="w-400px inset-is--36px inset-be--52px" lt-md="w-20vw inset-is--4vw inset-be-0"
    />
    <Icon
      name="FootTopBarBg" class="pointer-events-none absolute aspect-ratio-115/109 h-auto c-#fff c-op-5 md:hidden flip-rtl"
      lt-md="w-30vw inset-ie--10vw inset-bs-22px"
    />

    <div class="view-content" lt-md="py-40px" md="py-32px">
      <div
        class="flex items-center justify-between"
        lt-md="text-center flex-col" md="gap-40px"
      >
        <div>
          <p class="mb-16px font-800 lt-md:lh-1.5em" text="28px md:32px" c="#fff op-90">
            {{ $t('layout.foot_desc1') }}
          </p>
          <h2 class="max-w-90vw py-8px font-400" text="16px md:24px" c="#fff op-70">
            {{ $t('layout.foot_desc2') }}
          </h2>
        </div>

        <ClientOnly>
          <a
            v-if="isWindows" v-track="{ id: 'web-layout-footbar-download', click: { value: { page: currentRouteName() } } }"
            :href="link" class="ld-button min-w-176px of-hidden b-0 rd-8px from-#F7F662 to-#FFDE41 bg-gradient-to-r p-0"
            lt-md="text-16px font-800" md="text-24px font-700"
          >
            <i class="block size-64px from-#F7F662 to-#FFDE41 bg-gradient-to-r">
              <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.2351 38.5684V44.6747H26.4266V38.5684H20.2351ZM20.2351 44.6747V43.4566L19 44.6747H20.2351Z" fill="#181818" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M32.6179 20.25C25.849 20.25 20.3472 25.6128 20.251 32.257V36.5282H26.4425V32.257C26.5387 28.9823 29.2655 26.3563 32.6179 26.3563C36.0344 26.3563 38.8094 29.0931 38.8094 32.4626C38.8094 35.8322 36.0344 38.5689 32.6179 38.5689H28.4956V44.6753H32.6179C39.451 44.6753 45.0008 39.2017 45.0008 32.4626C45.0008 25.7235 39.451 20.25 32.6179 20.25Z" fill="#181818" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M36.3387 31.672H32.5693L34.061 27.3691L28.4951 33.3173H32.1362L30.7728 37.5569L36.3387 31.672Z" fill="#181818" />
              </svg>
            </i>
            <span class="min-w-180px px-18px text-center text-black">{{ $t('layout.download') }}</span>
          </a>

          <template #fallback>
            <a
              :href="link" class="ld-button min-w-176px of-hidden b-0 rd-8px from-#F7F662 to-#FFDE41 bg-gradient-to-r p-0"
              lt-md="text-16px font-800" md="text-24px font-700"
            >

              <i class="block size-64px from-#F7F662 to-#FFDE41 bg-gradient-to-r">
                <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M20.2351 38.5684V44.6747H26.4266V38.5684H20.2351ZM20.2351 44.6747V43.4566L19 44.6747H20.2351Z" fill="#181818" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M32.6179 20.25C25.849 20.25 20.3472 25.6128 20.251 32.257V36.5282H26.4425V32.257C26.5387 28.9823 29.2655 26.3563 32.6179 26.3563C36.0344 26.3563 38.8094 29.0931 38.8094 32.4626C38.8094 35.8322 36.0344 38.5689 32.6179 38.5689H28.4956V44.6753H32.6179C39.451 44.6753 45.0008 39.2017 45.0008 32.4626C45.0008 25.7235 39.451 20.25 32.6179 20.25Z" fill="#181818" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M36.3387 31.672H32.5693L34.061 27.3691L28.4951 33.3173H32.1362L30.7728 37.5569L36.3387 31.672Z" fill="#181818" />
                </svg>
              </i>
              <span class="min-w-180px px-18px text-center text-black">{{ $t('layout.download') }}</span>
            </a>
          </template>
        </ClientOnly>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>

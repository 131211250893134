<script lang="ts" setup>
import { NuxtLink } from '#components'

const { t } = useI18n()
const { toAppType, toIndex, toAboutUs } = useRouterToPage()

const gameNav = reactive(Array.from({ length: 9 }, (_, i) => t(`nav.games_sec_nav${i + 1}`).split('|') as [string, number]))
</script>

<template>
  <div class="flex items-center gap-32px">
    <div class="text-16px">
      <NuxtLink v-track="{ id: 'web-layout-nav', click: { value: { name: $t('layout.home') } } }" class="flex cursor-pointer c-#fff c-op-90 font-700 transition-all-100 hover:c-op-100" :to="toIndex({ hasReturnUrl: true })">
        {{ $t('layout.home') }}
      </NuxtLink>
    </div>

    <LdDropdown class="text-16px" :list="gameNav">
      <div class="c-#fff c-op-90 transition-all-100 hover:c-op-100">
        <span class="mr-6px font-700">{{ $t('nav.games_nav') }}</span>
      </div>

      <template #item="{ item }">
        <LdOptionsItem :is="NuxtLink" v-track="{ id: 'web-layout-nav', click: { value: { name: item[0] } } }" :to="toAppType(item[1], { hasReturnUrl: true })">
          {{ item[0] }}
        </LdOptionsItem>
      </template>
    </LdDropdown>

    <div class="text-16px">
      <NuxtLink v-track="{ id: 'web-layout-nav', click: { value: { name: $t('layout.about_us') } } }" class="flex cursor-pointer c-#fff c-op-90 font-700 transition-all-100 hover:c-op-100" :to="toAboutUs({ hasReturnUrl: true })">
        {{ $t('layout.about_us') }}
      </NuxtLink>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>

import { getBCP47byLanguageCode, initLdSdk } from 'ld-web-sdk'

export function useUserInfo() {
  const { $i18n: { locale } } = useNuxtApp()
  const sdkInit = useState<null | Promise<void>>('wait-sdk-init', () => null)

  async function initSdk() {
    if (sdkInit.value)
      return

    sdkInit.value = initLdSdk({
      region: 'oversea',
      appId: '6666',
      extAppId: '100039',
      languageCode: getBCP47byLanguageCode(locale.value),
      develop: !isProd(),
      channelId: '10100',
      subChannelId: '10101',
      secreKey: '',
    })
  }

  return {
    sdkInit,
    initSdk,
  }
}

export enum EasyfunGameType { CLOUD = 1, WEBSITE = 2 }
/** 云游信息 */
export interface EasyfunGameLite {
  banner: string
  category: string
  gameId: number
  gameName: string
  gameType: EasyfunGameType
  icon: string
  packageName: string
  /** 地区 */
  region: string
  screenOrientation: 1 | 0
  status: 1 | 0
  seo: string
  /** 游戏中心对应的seo key为语言 */
  mnqSeo: Record<string, string>
}

export interface EasyfunGameJson extends Pick<EasyfunGameLite, 'gameType' | 'icon' | 'packageName' | 'seo'> {
  mnqInfo: {
    gameName: string
    typeName: string
    seo?: Record<string, string>
  }
}

<script setup lang="ts">
const visible = defineModel<boolean>('modelValue', { required: true })

const { t } = useI18n()

const { toIndex, toAppType } = useRouterToPage()

const navVisible = ref(false)
const gameNav = reactive(Array.from({ length: 9 }, (_, i) => t(`nav.games_sec_nav${i + 1}`).split('|')))

const langVisible = ref(false)
const { languageList } = storeToRefs(useLanguageStore())

function switchAccordion(key: 'nav' | 'lang') {
  if (key === 'nav') {
    navVisible.value = !navVisible.value
    langVisible.value = false
  }
  else {
    langVisible.value = !langVisible.value
    navVisible.value = false
  }
}

function hideAll() {
  visible.value = false
  navVisible.value = false
  langVisible.value = false
}

// 禁用滚动
const isLocked = useScrollLock(import.meta.client ? window : undefined)
watch(visible, (v) => {
  isLocked.value = v
})
</script>

<template>
  <div class="absolute left-0 top-0">
    <LdFold :visible="visible">
      <div class="h-100vh w-100vw flex flex-col bg-#1D1B31">
        <div class="h-[--header-height] flex flex-shrink-0 items-center justify-between px-20px">
          <NuxtLink class="block" :to="toIndex({ hasReturnUrl: true })" aria-label="home page">
            <Icon name="LogoName" class="aspect-ratio-245/40 h-auto c-#fff" w="147px md:162px" />
          </NuxtLink>
          <div class="size-32px flex-center text-20px" @click="visible = !visible">
            <Icon name="Close" class="c-#fff c-op-90" />
          </div>
        </div>

        <div class="flex-1 text-16px">
          <NuxtLink v-track="{ id: 'web-layout-nav', click: { value: { name: $t('layout.home') } } }" class="h-56px flex items-center px-20px c-#fff c-op-90" :to="toIndex({ hasReturnUrl: true })" @click="hideAll">
            <span class="mr-6px font-700">{{ $t('layout.home') }}</span>
          </NuxtLink>
          <div class="mx-20px h-1px rd-full bg-#fff bg-op-10" />

          <LdFold :visible="navVisible" class="min-h-56px op-100">
            <div class="w-100vw flex flex-col gap-24px py-24px">
              <div class="flex items-center justify-between px-20px" @click="switchAccordion('nav')">
                <div class="flex-1 c-#fff c-op-90">
                  <span class="mr-6px font-700">{{ $t('nav.games_nav') }}</span>
                </div>
                <div class="flex-1 text-end">
                  <Icon name="ArrowDown" class="v-top transition-all-300" :class="navVisible && 'rotate-180'" />
                </div>
              </div>

              <div v-for="(item, i) in gameNav" :key="i" class="flex items-center justify-between px-20px">
                <NuxtLink v-track="{ id: 'web-layout-nav', click: { value: { name: item[0] } } }" class="flex-1 c-#fff c-op-70" :to="toAppType(item[1] as any, { hasReturnUrl: true })" @click="hideAll">
                  {{ item[0] }}
                </NuxtLink>
              </div>
            </div>
          </LdFold>
          <div class="mx-20px h-1px rd-full bg-#fff bg-op-10" />

          <LdFold :visible="langVisible" class="min-h-56px op-100">
            <div class="w-100vw flex flex-col gap-24px py-24px">
              <div class="flex items-center justify-between px-20px" @click="switchAccordion('lang')">
                <div class="flex-1 c-#fff c-op-90">
                  <span class="mr-6px font-700">{{ $t('Language.language_name') }}</span>
                </div>
                <div class="flex-1 text-end">
                  <Icon name="ArrowDown" class="v-top transition-all-300" :class="langVisible && 'rotate-180'" />
                </div>
              </div>

              <div v-for="(item, i) in languageList" :key="i" class="flex items-center justify-between px-20px">
                <a v-track="{ id: 'web-layout-language', click: { value: { page: currentRouteName(), from: $i18n.locale, to: item.code } } }" class="flex-1 c-#fff c-op-70" :href="item.domain" target="_blank">
                  {{ item.name }}
                </a>
              </div>
            </div>
          </LdFold>
          <div class="mx-20px h-1px rd-full bg-#fff bg-op-10" />
        </div>
      </div>
    </LdFold>
  </div>
</template>

<style lang="scss" scoped>
</style>

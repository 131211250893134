/**
 * TS Omit 方法
 *
 * 过滤掉所选的key值
 */
export function omit<T extends Record<string, any>, K extends keyof T>(
  obj: T,
  keys: K[],
): Omit<T, K> {
  return Object.fromEntries(
    Object.entries(obj).filter(([k]) => !keys.includes(k as K)),
  ) as Omit<T, K>
}

/** TS Pick 方法 */
export function pick<T extends Record<string, any>, K extends keyof T>(
  obj: T,
  keys: K[],
): Pick<T, K> {
  return Object.fromEntries(
    Object.entries(obj).filter(([k]) => keys.includes(k as K)),
  ) as Pick<T, K>
}

/** 客户端打印日志 */
export const clientLog = import.meta.client
  ? console
  : new Proxy(console, { get: () => () => {} })

/** 服务端打印日志 */
export const serverLog = import.meta.server
  ? console
  : new Proxy(console, { get: () => () => {} })

/**
 * 获取public文件夹下的图片
 * @param path 不以/开头
 * @returns CDN地址 + PATH
 */
export function getPublicImg(path: string) {
  const config = useRuntimeConfig()
  return `${config.app.cdnURL}${path}`
}

/**
 * 创建promise手动控制函数
 * @returns Promise, Resolve, Reject
 */
export function createControlledPromise() {
  let resolve: () => void = () => {}
  let reject: () => void = () => {}
  const promise = new Promise<void>((res, rej) => {
    resolve = res as any
    reject = rej
  })

  return { promise, resolve, reject }
}

/**
 * @description 获取随机字符串(ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789)
 * @example: getRandomString(4) ===> abd3
 * @param {number} length
 * @return {*}
 */
export function getRandomString(length: number): string {
  const str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let res = ''
  for (let i = 0; i < length; i += 1)
    res += str.charAt(getRangeRandom(0, str.length - 1))

  return res
}

/**
 * @description 获取[min,max]之间的随机整数。
 * @example getRangeRandom([-10,100]) ===> -8
 * @param {number} min
 * @param {number} max
 * @return {*}
 */
export function getRangeRandom(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

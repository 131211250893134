<script setup lang="ts">
import { useMediaShare } from 'ld-share-sdk'
// 海外分享
const topMediaNames: MediaName[] = ['facebook', 'X', 'reddit']
const spriteIconStyle = getSpriteIconStyle(44)

const { windowScrollTo, conf, isRtl } = useLayout()
const { openshareDialog } = useDialog()
const { clickShare } = useMediaShare({ fbAppId: '160640943743010' })

const anchorRef = ref<HTMLDivElement>()
const { right } = useElementBounding(anchorRef)
const offset = computed<number>(() => isRtl.value ? -84 : 40)
const deskPosition = computed(() => `${unref(right) + unref(offset)}px`)
const mobPosition = computed(() => `${unref(right) - unref(offset)}px`)
const bottomOffset = computed(() => `${unref(conf).sidebar.bottomOffset + unref(offset)}px`)
</script>

<template>
  <div class="relative">
    <div class="view-content h-0 w-full flex justify-end">
      <div ref="anchorRef" class="sidebar-anchor" />

      <Transition name="sidebar">
        <div
          v-if="conf.sidebar.visible" class="sidebar-box z-9 w-44px items-center"
          :class="[conf.sidebar.fixed ? 'fixed' : 'absolute', conf.footBar.fixed && 'footBar-fixed']"
          flex="~ col gap-y-8px"
        >
          <!-- 媒体分享列表 -->
          <ul v-if="conf.sidebar.share" class="of-hidden rd-8px shadow-[0px_2px_8px_0px_#1F1E3D33] md:rd-4px" flex="~ col">
            <li v-for="name in topMediaNames" :key="name" v-track="{ id: 'web-layout-share', click: { value: { page: currentRouteName(), type: name } } }" class="size-44px flex-center cursor-pointer of-hidden lt-md:hidden" :data-share="name" @click="clickShare">
              <div class="size-44px origin-c transform-gpu transition-all-100 hover-scale-110">
                <img :style="spriteIconStyle[name]" class="relative float-left aspect-ratio-269/14 h-44px" src="/support.webp" :alt="`go to ${name}`" loading="lazy">
              </div>
            </li>

            <li class="relative size-44px cursor-pointer of-hidden bg-purple-5" @click="openshareDialog()">
              <div class="size-44px origin-c transform-gpu transition-all-100 hover-scale-110" flex="~ center">
                <Icon name="MediaShare" class="size-24px" />
              </div>
            </li>
          </ul>

          <!-- 回到顶部 -->
          <div v-if="conf.mode === 'light'" class="size-44px cursor-pointer rd-8px shadow-[0px_2px_8px_0px_#1F1E3D33] transition-colors-100 md:rd-4px" flex="~ center" bg="#fff" c="lt-md:purple-5! lt-md:op-100! #161636 op-50 hover:purple-5 hover:op-100" @click="windowScrollTo(0)">
            <Icon name="GoTop" class="size-24px" />
          </div>

          <div v-else-if="conf.mode === 'dark'" class="m--1px box-content size-44px cursor-pointer rd-8px transition-colors-100 md:rd-4px" flex="~ center" b="~ solid transparent" bg="clip-[padding-box,border-box] image-[linear-gradient(#1D1B31,#1D1B31),linear-gradient(-16deg,transparent_71%,#fff3)] origin-[padding-box,border-box]" c="lt-md:op-100! #fff op-50 md-hover:op-100" @click="windowScrollTo(0)">
            <Icon name="GoTop" class="size-24px" />
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sidebar-enter-active,
.sidebar-leave-active {
  transition-property: opacity,transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.sidebar-enter-from,
.sidebar-leave-to {
  transform: translate3d(0, 50px, 0);
  opacity: 0;
}

@media (max-width: 767.9px) {
  .sidebar-box {
    bottom: v-bind(bottomOffset);
  }
}

@media (min-width: 768px) {
  .sidebar-box:not(.footBar-fixed) {
    bottom: 24px;
  }
  .sidebar-box{
    bottom: v-bind(bottomOffset);
  }
}

@media (max-width: 1479.9px) {
  .sidebar-box {
    left: v-bind(mobPosition);
  }
}

@media (min-width: 1480px) {
  .sidebar-box {
    left: v-bind(deskPosition);
  }
}
</style>

import { getFuzzySearchApi } from '~/api'
import type { AppSearchLite } from '~/types/search'

/** 搜索 */
export function useSearch(input: Ref<string>) {
  const { searchReport } = useLogReport()

  const loading = ref<boolean>(false)

  const query = computed(() => unref(input).trim())

  const data = ref<AppSearchLite[]>([])

  watch(input, () => {
    loading.value = true
  })

  debouncedWatch(input, async () => {
    const wanna = unref(query)

    if (wanna) {
      const fuzzyData = await getFuzzySearchApi(wanna)
      searchReport(wanna)
      data.value = fuzzyData
    }

    loading.value = false
  }, { debounce: 500 })

  return { query, loading, data }
}

/** 媒体雪碧图索引 X有分黑白 */
enum SpriteIndex {
  facebook,
  reddit,
  X_dark,
  X_light,
  whatsapp,
  line,
  vk,
  discord,
  youtube,
  kakao,
  cafe,
  gmail,
  telegram,
  messenger,
  pinterest,
  linkedin,
}

/** 媒体名字 */
export type MediaName = Exclude<keyof typeof SpriteIndex, 'X_dark' | 'X_light'> | 'X'

// 雪碧图信息
interface SpriteImgInfo {
  width: number
  height: number
  iconSum: number
}

function creatSpriteIconStyle(iconSize: number, imgInfo: SpriteImgInfo) {
  const imgWidth = imgInfo.width / imgInfo.height * iconSize
  const stepSize = (imgWidth - iconSize) / (imgInfo.iconSum - 1)
  return (index: number, fixed = 4) => {
    const left = `-${(stepSize * index).toFixed(fixed)}px`
    return { left }
  }
}

/**
 * 获取分享渠道的icon偏移量
 * @param iconSize icon大小
 * @param mode 当前背景明暗
 */
export function getSpriteIconStyle(iconSize: number, mode?: 'dark' | 'light'): Record<MediaName, Record<string, string>> {
  const iconSum = Object.keys(SpriteIndex).length / 2
  const spriteImgInfo = { width: 2152, height: 112, iconSum }
  const getIconStyle = creatSpriteIconStyle(iconSize, spriteImgInfo)

  return {
    facebook: getIconStyle(SpriteIndex.facebook),
    reddit: getIconStyle(SpriteIndex.reddit),
    X: mode === 'dark' ? getIconStyle(SpriteIndex.X_light) : getIconStyle(SpriteIndex.X_dark),
    whatsapp: getIconStyle(SpriteIndex.whatsapp),
    line: getIconStyle(SpriteIndex.line),
    vk: getIconStyle(SpriteIndex.vk),
    discord: getIconStyle(SpriteIndex.discord),
    youtube: getIconStyle(SpriteIndex.youtube),
    kakao: getIconStyle(SpriteIndex.kakao),
    cafe: getIconStyle(SpriteIndex.cafe),
    gmail: getIconStyle(SpriteIndex.gmail),
    telegram: getIconStyle(SpriteIndex.telegram),
    messenger: getIconStyle(SpriteIndex.messenger),
    pinterest: getIconStyle(SpriteIndex.pinterest),
    linkedin: getIconStyle(SpriteIndex.linkedin),
  }
}

<script setup lang="ts">
import { useMediaShare } from 'ld-share-sdk'

const { shareConfig } = useDialog()

const { clickShare } = useMediaShare({ fbAppId: '160640943743010' })
const shareNames: MediaName[] = ['facebook', 'reddit', 'X', 'line', 'whatsapp', 'vk', 'gmail', 'linkedin', 'pinterest', 'messenger', 'telegram']
const spriteIconStyle = getSpriteIconStyle(56)
</script>

<template>
  <LdDialog v-model="shareConfig.visible" class="lt-md:w-full" show-close direction-class="lt-md:items-end">
    <div class="w-full rd-t-8px bg-#fff p-24px md:rd-b-8px">
      <h3 class="mb-24px text-center text-24px c-#161636 font-900 lh-32px">
        {{ $t('share.share_title') }}
      </h3>

      <ul class="grid grid-cols-3 mb-16px items-center gap-24px at-sm:grid-cols-5 md:gap-x-50px md:px-22px">
        <li v-for="name in shareNames" :key="name" v-track="{ id: 'web-layout-share', click: { value: { page: currentRouteName(), type: name } } }" class="group flex-center flex-col cursor-pointer" :data-share="name" @click="clickShare">
          <div class="size-58px of-hidden rd-8px" b="~ solid #000 op-8">
            <div class="size-56px transform-gpu transition-all-300 group-hover:scale-110">
              <img :style="spriteIconStyle[name]" class="relative float-left aspect-ratio-269/14 h-56px" src="/support.webp" :alt="`go to ${name}`" loading="lazy">
            </div>
          </div>
          <p class="mt-8px text-center text-14px c-#161636 c-op-70 lh-20px group-hover:c-purple-5">
            {{ name }}
          </p>
        </li>
      </ul>
    </div>
  </LdDialog>
</template>

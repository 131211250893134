function checkRunTimeEnvironment(key: string) {
  const { public: { env } } = useRuntimeConfig()
  return env === key
}

/** 判断是否开发环境 */
export function isDev() {
  return checkRunTimeEnvironment('development')
}

/** 判断是否测试环境 */
export function isTest() {
  return checkRunTimeEnvironment('test')
}

/** 判断是否预发布环境 */
export function isRelease() {
  return checkRunTimeEnvironment('release')
}

/** 判断是否正式环境 */
export function isProd() {
  return checkRunTimeEnvironment('production')
}

/** 获取当前路由的路由名 */
export function currentRouteName() {
  const route = useRoute()
  const routeBaseName = useRouteBaseName()
  return routeBaseName(route)!
}

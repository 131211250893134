<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
})

const { conf, setConf } = useLayout()

const { toPrivacy, toGDPR, toAboutUs } = useRouterToPage()

const topBarRef = ref<HTMLElement>()
const topBarVisible = useElementVisibility(topBarRef)
const { height } = useElementBounding(topBarRef)

const footRef = ref<HTMLElement>()
const footVisible = useElementVisibility(footRef)

// 当能看到任意底部的时候回到顶部就跟随文档流
watch([topBarVisible, footVisible, height], ([v1, v2, h]) => {
  setConf({
    footBar: { fixeding: !v2 },
    sidebar: { fixed: !v1 && !v2, bottomOffset: !v1 ? h : 0 },
  })
})
</script>

<template>
  <section ref="topBarRef">
    <LayoutFooterFootBar :visible="conf.footBar.visible" />
  </section>

  <ClientOnly>
    <Teleport to="body">
      <LayoutFooterFootBar v-show="conf.footBar.fixeding" fixed-mode :visible="conf.footBar.fixed && $device.isWindows" />
    </Teleport>
  </ClientOnly>

  <!-- && isWindows -->

  <footer
    ref="footRef" :class="{
      'bg-[var(--bg-color)] c-#fff [&_.title]:c-#fff [&_.title]:c-op-90  [&_.content]:c-#fff [&_.content]:c-op-50': conf.mode === 'dark',
      'bg-#fff c-#161636  [&_.content]:c-#161636 [&_.content]:c-op-70': conf.mode === 'light',
    }"
  >
    <div
      class="grid grid-areas-[logo]-[follow]-[support]-[about]-[business]-[link] py-40px md:grid-areas-[logo_support]-[follow_about]-[business_link]"
      lt-md="px-20px" md="gap-x-80px"
      v-bind="$attrs"
    >
      <div class="grid-area-[logo]">
        <Icon name="LogoName" class="mb-16px aspect-ratio-245/40 h-auto" w="198px" />
        <p class="content text-14px lh-20px">
          {{ $t('layout.footer_text') }}
        </p>
      </div>

      <div class="grid-area-[support]" mt="lt-md:40px">
        <p class="title mb-14px text-16px font-700">
          {{ $t('layout.contact_us') }}
        </p>

        <div class="content text-14px lh-18px">
          <p mb="12px md:10px" v-html="$t('layout.contact_text1')" />
          <p mb="12px md:10px">
            {{ $t('layout.contact_text2') }}
          </p>
          <p>
            <span class="block">{{ $t('layout.contact_text3') }}</span>
            <span class="mt-2px block text-12px">{{ $t('layout.contact_text4') }}</span>
          </p>
        </div>
      </div>

      <div class="grid-area-[follow]" mt="24px md:18px">
        <!-- <p class="title font-700 text-16px mb-14px">
          {{ $t('layout.follow_us') }}
        </p> -->

        <LayoutFooterFollowList :mode="conf.mode" />
      </div>

      <div class="grid-area-[about]" mt="32px md:24px">
        <p class="title mb-14px text-16px font-700">
          {{ $t('layout.about_us') }}
        </p>

        <NuxtLink class="content text-14px lh-18px hover:c-op-100" :class="conf.mode === 'dark' ? 'active:c-purple-5 hover:c-purple-6' : 'active:c-purple-4 hover:c-purple-5'" :to="toAboutUs({ hasReturnUrl: true })">
          {{ $t('layout.about_us') }}
        </NuxtLink>
      </div>

      <div class="grid-area-[business] text-12px lh-18px" mt="32px md:24px">
        <p class="content">
          ©2022 LDPLAYER.NET , ALL RIGHTS RESERVED.JUST OKAY LIMITED ,6/F MANULIFE PLACE 348 KWUN TONG ROAD KL HONGKONG
        </p>
      </div>

      <div class="grid-area-[link] self-end text-12px lh-18px all:transition-all-100" mt="32px md:24px">
        <NuxtLink class="content hover:c-op-100" :class="conf.mode === 'dark' ? 'active:c-purple-5 hover:c-purple-6' : 'active:c-purple-4 hover:c-purple-5'" :to="toPrivacy()" target="_blank">
          {{ $t('layout.privacy') }}
        </NuxtLink>
        <span class="content mx-0.5em">|</span>
        <NuxtLink class="content hover:c-op-100" :class="conf.mode === 'dark' ? 'active:c-purple-5 hover:c-purple-6' : 'active:c-purple-4 hover:c-purple-5'" :to="toGDPR()" target="_blank">
          {{ $t('layout.gdpr') }}
        </NuxtLink>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
</style>

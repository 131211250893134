import { ofetch } from 'ofetch'
import type { SupportLocale } from '~/config/i18n'
import { type EasyfunGameJson, EasyfunGameType } from '~/types/easyfun'

/** 获取云游游戏信息 */
export function useEasyfunGameJson() {
  interface EasyfunState {
    time: number
    games: EasyfunGameJson[]
    pkgMap: Record<string, EasyfunGameJson>
  }

  const initEasyfunState = (): EasyfunState => ({
    time: 0,
    games: [],
    pkgMap: {},
  })

  const { $i18n: { locale } } = useNuxtApp()
  const state = useState<EasyfunState>('easyfun-state', () => shallowRef(initEasyfunState()))

  /** 刷新json数据 */
  async function refreshJson() {
    try {
      if (isJsonExpired()) {
        const url = `https://res.ldrescdn.com/${(isTest() || isDev()) ? 'ldx_test' : 'ldx'}/games/easyfun.json`
        const res = await ofetch<{ games: EasyfunGameJson[] }>(url, { parseResponse: JSON.parse, credentials: 'same-origin' })

        const games = res.games.filter(i => i.mnqInfo?.seo?.[locale.value as SupportLocale])
        const pkgMap = res.games.reduce<EasyfunState['pkgMap']>((prev, cur) => {
          if (cur.gameType === EasyfunGameType.CLOUD)
            prev[cur.packageName] = cur
          return prev
        }, {})
        state.value = {
          time: Date.now(),
          games,
          pkgMap,
        }
      }
    }
    catch {
      state.value = initEasyfunState()
    }
    return state.value
  }

  function isJsonExpired() {
    return Date.now() - state.value.time > 1000 * 60 * 60 * 24
  }

  function getInfoByPkg(pkg: string | undefined): EasyfunGameJson | undefined {
    return pkg ? state.value.pkgMap?.[pkg] : undefined
  }

  return {
    easyfunState: state,
    refreshJson,
    getInfoByPkg,
  }
}

import type { FetchOptions, FetchResponse } from 'ofetch'
import { ofetch } from 'ofetch'
import type { SupportLocale } from '~/config/i18n'

enum HttpResponseStatus {
  SUCCESS = 200,
}

interface RequestOptions {
  /** 是否直接返回响应内容 这里不会走reject拦截 无论对错直接返回 */
  isReturnNativeResponse?: boolean
  /** 用于处理返回内容 默认 true 只返回里面的data (分页的时候这里可设为false) */
  isTransformResponse?: boolean
  /** 是否处理异常状态码 默认 true */
  isHandleErrorCode?: boolean
  /** 用于某些接口配置的请求语言 */
  requestLocale?: SupportLocale
}

interface HandleFn {
  /** 各业务方校验状态码 */
  isErrorCode?: (code: number) => boolean
  /** 各业务方处理异常状态码 */
  handleErrorCode?: (code: number) => void
  /** 各业务方的请求配置 */
  handleFetchOptions?: (opts: RequestOptions, headers: Record<string, string>) => FetchOptions
  /** 各业务方的响应逻辑 */
  handleResponse?: (response: FetchResponse<ResponseType>) => void
}

/** 通用的请求逻辑 */
function generateHttp(method: string, handleFn?: HandleFn) {
  return <T>(url: string, params: FetchOptions = {}, opts?: RequestOptions) => {
    /** 默认的请求配置 */
    const defReqOpts: RequestOptions = { isTransformResponse: true, isHandleErrorCode: true }
    /** 请求配置 */
    const { isReturnNativeResponse, isTransformResponse, isHandleErrorCode } = loAssign(defReqOpts, opts)

    const headers = useRequestHeaders()
    /** 各业务方的请求配置 */
    const dynamicOpts = handleFn?.handleFetchOptions?.(defReqOpts, headers) || {}

    /** 通用的请求处理配置内容 */
    const defOpts: FetchOptions = {
      method,
      onRequest(context) {
        if (!isDev()) {
          const ip = (context.options.headers as any)?.['x-forwarded-for'] || 'unknow'
          const userAgent = (context.options.headers as any)?.['user-agent'] || 'unknow'
          serverLog.log(`[${+new Date()}] request ip: ${ip}, url:${url}, userAgent: ${userAgent}`)
        }
      },
      onResponse({ response }) {
        const { code, data, msg } = response._data

        /** 直接返回响应内容 */
        if (isReturnNativeResponse)
          return response._data

        const responseFail = response.status !== HttpResponseStatus.SUCCESS
        const apiFail = handleFn?.isErrorCode?.(code)
        /** 如果有异常处理函数，则执行异常处理函数，否则抛出异常 */
        if (responseFail || apiFail) {
          serverLog.error(`response fail [${+new Date()}]: ${response.url}  error => ${JSON.stringify(response._data)}`)

          if (isHandleErrorCode) {
            handleFn?.handleErrorCode?.(code)
          }

          return Promise.reject(code || msg || response.statusText)
        }

        /** 处理返回内容 */
        if (isTransformResponse)
          response._data = data

        /** 各业务方的响应逻辑 */
        handleFn?.handleResponse?.(response)
      },
      onRequestError({ request, error }) {
        serverLog.error(`request error [${+new Date()}]: ${request} error => ${error?.message}`)
      },
      onResponseError({ response, options: { baseURL }, request }) {
        serverLog.error(`response error [${+new Date()}]: ${baseURL}${request}  error => ${response?._data?.msg || response?._data?.code}`)
      },
    }
    return ofetch<T>(url, loMerge(defOpts, dynamicOpts, params) as any)
  }
}

export function createHttp(handleFn?: HandleFn) {
  return {
    get: generateHttp('get', handleFn),
    post: generateHttp('post', handleFn),
    put: generateHttp('put', handleFn),
  }
}


// @ts-nocheck


export const localeCodes =  [
  "tw",
  "kr",
  "vn",
  "th",
  "ru",
  "pt",
  "es",
  "fr",
  "de",
  "jp",
  "id",
  "ar",
  "en"
]

export const localeLoaders = {
  "tw": [{ key: "../locales/zh_TW.json", load: () => import("../locales/zh_TW.json" /* webpackChunkName: "locale__root_workspace_ld_x_gw_Wdkc_locales_zh_TW_json" */), cache: true }],
  "kr": [{ key: "../locales/ko_KR.json", load: () => import("../locales/ko_KR.json" /* webpackChunkName: "locale__root_workspace_ld_x_gw_Wdkc_locales_ko_KR_json" */), cache: true }],
  "vn": [{ key: "../locales/vi_VN.json", load: () => import("../locales/vi_VN.json" /* webpackChunkName: "locale__root_workspace_ld_x_gw_Wdkc_locales_vi_VN_json" */), cache: true }],
  "th": [{ key: "../locales/th_TH.json", load: () => import("../locales/th_TH.json" /* webpackChunkName: "locale__root_workspace_ld_x_gw_Wdkc_locales_th_TH_json" */), cache: true }],
  "ru": [{ key: "../locales/ru_RU.json", load: () => import("../locales/ru_RU.json" /* webpackChunkName: "locale__root_workspace_ld_x_gw_Wdkc_locales_ru_RU_json" */), cache: true }],
  "pt": [{ key: "../locales/pt_PT.json", load: () => import("../locales/pt_PT.json" /* webpackChunkName: "locale__root_workspace_ld_x_gw_Wdkc_locales_pt_PT_json" */), cache: true }],
  "es": [{ key: "../locales/es_ES.json", load: () => import("../locales/es_ES.json" /* webpackChunkName: "locale__root_workspace_ld_x_gw_Wdkc_locales_es_ES_json" */), cache: true }],
  "fr": [{ key: "../locales/fr_FR.json", load: () => import("../locales/fr_FR.json" /* webpackChunkName: "locale__root_workspace_ld_x_gw_Wdkc_locales_fr_FR_json" */), cache: true }],
  "de": [{ key: "../locales/de_DE.json", load: () => import("../locales/de_DE.json" /* webpackChunkName: "locale__root_workspace_ld_x_gw_Wdkc_locales_de_DE_json" */), cache: true }],
  "jp": [{ key: "../locales/ja_JP.json", load: () => import("../locales/ja_JP.json" /* webpackChunkName: "locale__root_workspace_ld_x_gw_Wdkc_locales_ja_JP_json" */), cache: true }],
  "id": [{ key: "../locales/in_ID.json", load: () => import("../locales/in_ID.json" /* webpackChunkName: "locale__root_workspace_ld_x_gw_Wdkc_locales_in_ID_json" */), cache: true }],
  "ar": [{ key: "../locales/ar_IL.json", load: () => import("../locales/ar_IL.json" /* webpackChunkName: "locale__root_workspace_ld_x_gw_Wdkc_locales_ar_IL_json" */), cache: true }],
  "en": [{ key: "../locales/en_US.json", load: () => import("../locales/en_US.json" /* webpackChunkName: "locale__root_workspace_ld_x_gw_Wdkc_locales_en_US_json" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "tw",
      "language": "zh",
      "domain": "https://tw.ldplayerx.com",
      "name": "繁體中文",
      "alternate": "zh-hant",
      "rel": "nofollow",
      "files": [
        "/root/workspace/ld_x_gw_Wdkc/locales/zh_TW.json"
      ]
    },
    {
      "code": "kr",
      "language": "ko",
      "domain": "https://kr.ldplayerx.com",
      "name": "한국어",
      "alternate": "ko",
      "rel": "nofollow",
      "files": [
        "/root/workspace/ld_x_gw_Wdkc/locales/ko_KR.json"
      ]
    },
    {
      "code": "vn",
      "language": "vn",
      "domain": "https://vi.ldplayerx.com",
      "name": "Tiếng Việt",
      "alternate": "vi",
      "rel": "nofollow",
      "files": [
        "/root/workspace/ld_x_gw_Wdkc/locales/vi_VN.json"
      ]
    },
    {
      "code": "th",
      "language": "th",
      "domain": "https://th.ldplayerx.com",
      "name": "ภาษาไทย",
      "alternate": "th",
      "rel": "nofollow",
      "files": [
        "/root/workspace/ld_x_gw_Wdkc/locales/th_TH.json"
      ]
    },
    {
      "code": "ru",
      "language": "ru",
      "domain": "https://ru.ldplayerx.com",
      "name": "Pусский",
      "alternate": "ru",
      "rel": "nofollow",
      "files": [
        "/root/workspace/ld_x_gw_Wdkc/locales/ru_RU.json"
      ]
    },
    {
      "code": "pt",
      "language": "pt",
      "domain": "https://pt.ldplayerx.com",
      "name": "Português",
      "alternate": "pt-br",
      "rel": "nofollow",
      "files": [
        "/root/workspace/ld_x_gw_Wdkc/locales/pt_PT.json"
      ]
    },
    {
      "code": "es",
      "language": "es",
      "domain": "https://es.ldplayerx.com",
      "name": "Español",
      "alternate": "es",
      "rel": "nofollow",
      "files": [
        "/root/workspace/ld_x_gw_Wdkc/locales/es_ES.json"
      ]
    },
    {
      "code": "fr",
      "language": "fr",
      "domain": "https://fr.ldplayerx.com",
      "name": "Français",
      "alternate": "fr",
      "rel": "nofollow",
      "files": [
        "/root/workspace/ld_x_gw_Wdkc/locales/fr_FR.json"
      ]
    },
    {
      "code": "de",
      "language": "de",
      "domain": "https://de.ldplayerx.com",
      "name": "Deutsch",
      "alternate": "de",
      "rel": "nofollow",
      "files": [
        "/root/workspace/ld_x_gw_Wdkc/locales/de_DE.json"
      ]
    },
    {
      "code": "jp",
      "language": "ja",
      "domain": "https://jp.ldplayerx.com",
      "name": "日本語",
      "alternate": "ja",
      "rel": "nofollow",
      "files": [
        "/root/workspace/ld_x_gw_Wdkc/locales/ja_JP.json"
      ]
    },
    {
      "code": "id",
      "language": "id",
      "domain": "https://id.ldplayerx.com",
      "name": "Indonesia",
      "alternate": "id",
      "rel": "nofollow",
      "files": [
        "/root/workspace/ld_x_gw_Wdkc/locales/in_ID.json"
      ]
    },
    {
      "code": "ar",
      "language": "ar",
      "domain": "https://ar.ldplayerx.com",
      "name": "العربية",
      "alternate": "ar",
      "rel": "nofollow",
      "files": [
        "/root/workspace/ld_x_gw_Wdkc/locales/ar_IL.json"
      ]
    },
    {
      "code": "en",
      "language": "en",
      "domain": "https://www.ldplayerx.com",
      "name": "English",
      "alternate": "x-default",
      "rel": "nofollow",
      "files": [
        "/root/workspace/ld_x_gw_Wdkc/locales/en_US.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "locales",
  "detectBrowserLanguage": false,
  "differentDomains": true,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "tw",
    "language": "zh",
    "domain": "https://tw.ldplayerx.com",
    "name": "繁體中文",
    "alternate": "zh-hant",
    "rel": "nofollow",
    "files": [
      {
        "path": "/root/workspace/ld_x_gw_Wdkc/locales/zh_TW.json"
      }
    ]
  },
  {
    "code": "kr",
    "language": "ko",
    "domain": "https://kr.ldplayerx.com",
    "name": "한국어",
    "alternate": "ko",
    "rel": "nofollow",
    "files": [
      {
        "path": "/root/workspace/ld_x_gw_Wdkc/locales/ko_KR.json"
      }
    ]
  },
  {
    "code": "vn",
    "language": "vn",
    "domain": "https://vi.ldplayerx.com",
    "name": "Tiếng Việt",
    "alternate": "vi",
    "rel": "nofollow",
    "files": [
      {
        "path": "/root/workspace/ld_x_gw_Wdkc/locales/vi_VN.json"
      }
    ]
  },
  {
    "code": "th",
    "language": "th",
    "domain": "https://th.ldplayerx.com",
    "name": "ภาษาไทย",
    "alternate": "th",
    "rel": "nofollow",
    "files": [
      {
        "path": "/root/workspace/ld_x_gw_Wdkc/locales/th_TH.json"
      }
    ]
  },
  {
    "code": "ru",
    "language": "ru",
    "domain": "https://ru.ldplayerx.com",
    "name": "Pусский",
    "alternate": "ru",
    "rel": "nofollow",
    "files": [
      {
        "path": "/root/workspace/ld_x_gw_Wdkc/locales/ru_RU.json"
      }
    ]
  },
  {
    "code": "pt",
    "language": "pt",
    "domain": "https://pt.ldplayerx.com",
    "name": "Português",
    "alternate": "pt-br",
    "rel": "nofollow",
    "files": [
      {
        "path": "/root/workspace/ld_x_gw_Wdkc/locales/pt_PT.json"
      }
    ]
  },
  {
    "code": "es",
    "language": "es",
    "domain": "https://es.ldplayerx.com",
    "name": "Español",
    "alternate": "es",
    "rel": "nofollow",
    "files": [
      {
        "path": "/root/workspace/ld_x_gw_Wdkc/locales/es_ES.json"
      }
    ]
  },
  {
    "code": "fr",
    "language": "fr",
    "domain": "https://fr.ldplayerx.com",
    "name": "Français",
    "alternate": "fr",
    "rel": "nofollow",
    "files": [
      {
        "path": "/root/workspace/ld_x_gw_Wdkc/locales/fr_FR.json"
      }
    ]
  },
  {
    "code": "de",
    "language": "de",
    "domain": "https://de.ldplayerx.com",
    "name": "Deutsch",
    "alternate": "de",
    "rel": "nofollow",
    "files": [
      {
        "path": "/root/workspace/ld_x_gw_Wdkc/locales/de_DE.json"
      }
    ]
  },
  {
    "code": "jp",
    "language": "ja",
    "domain": "https://jp.ldplayerx.com",
    "name": "日本語",
    "alternate": "ja",
    "rel": "nofollow",
    "files": [
      {
        "path": "/root/workspace/ld_x_gw_Wdkc/locales/ja_JP.json"
      }
    ]
  },
  {
    "code": "id",
    "language": "id",
    "domain": "https://id.ldplayerx.com",
    "name": "Indonesia",
    "alternate": "id",
    "rel": "nofollow",
    "files": [
      {
        "path": "/root/workspace/ld_x_gw_Wdkc/locales/in_ID.json"
      }
    ]
  },
  {
    "code": "ar",
    "language": "ar",
    "domain": "https://ar.ldplayerx.com",
    "name": "العربية",
    "alternate": "ar",
    "rel": "nofollow",
    "files": [
      {
        "path": "/root/workspace/ld_x_gw_Wdkc/locales/ar_IL.json"
      }
    ]
  },
  {
    "code": "en",
    "language": "en",
    "domain": "https://www.ldplayerx.com",
    "name": "English",
    "alternate": "x-default",
    "rel": "nofollow",
    "files": [
      {
        "path": "/root/workspace/ld_x_gw_Wdkc/locales/en_US.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"

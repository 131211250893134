import type { SupportLocale } from '~/config/i18n'

/** 相关下载逻辑 */
export function useDownload() {
  return {
    /** 助手下载链接生成逻辑 */
    getXLink,
    getApkLink,
  }
}

export interface DownloadLinkParams {
  /**
   * 下载链接 默认 https://res.ldrescdn.com/LDPlayerX/download/LDPlayerX.exe
   */
  url?: string
  /**
   * 跳转app包名 默认 ld
   */
  pkg?: string
  /**
   * 首次进入指定菜单 默认 ld 可选为 shop easyfun
   *
   * 2024.7.8 - 兼容广告游戏跳转的app包非当前语言，from也可能是包名对应的语言
   */
  from?: string
}

/**
 * 助手下载链接生成逻辑
 * @param {params} params 下载链接参数
 * @param {params} params.url 下载链接
 * @param {params} params.pkg 跳转app包名 默认 ld
 * @param {params} params.from 首次进入指定菜单 默认 ld 可选为 shop easyfun 语言
 * @desc 链接规则 [下载地址]?n=LDPlayerX_[语言]_[openid]_[跳转包名详情页]_[初次进入的菜单]_ld.exe
 */
function getXLink(params: DownloadLinkParams = {}) {
  const { public: { cdn } } = useRuntimeConfig()
  const { $i18n: { locale } } = useNuxtApp()
  const { queryData } = storeToRefs(useQueryStore())
  const filePath = (isTest() || isDev()) ? 'download-test' : 'download'
  return computed(() => {
    const realParams: DownloadLinkParams = Object.assign({ from: 'ld', pkg: 'ld', url: `${cdn}LDPlayerX/${filePath}/LDPlayerX.exe` }, params)

    const { openid, final } = unref(queryData)

    if (final) {
      if (['shop', 'cloud'].includes(final)) {
        realParams.from = final
      }
      else {
        realParams.pkg = final
      }
    }

    if (realParams.pkg === 'ld' && realParams.from === 'ld') {
      return `${realParams.url}?n=LDPlayerX_${locale.value}_${openid}_ld.exe`
    }
    else {
      return `${realParams.url}?n=LDPlayerX_${locale.value}_${openid}_${realParams.pkg}_${realParams.from}_ld.exe`
    }
  })
}

/**
 * 获取移动端下载链接
 * @param {params} params 下载链接参数
 * @param {params} params.url app 雷电apk下载地址
 * @param {params} params.pkg 没有apk的时候使用包名拼谷歌地址
 */
function getApkLink(params: Omit<DownloadLinkParams, 'from'> = {}) {
  const { $i18n: { locale } } = useNuxtApp()
  const hlgl: Record<SupportLocale, string> = {
    tw: 'hl=zh-tw&gl=tw',
    th: 'hl=th&gl=th',
    vn: 'hl=vi&gl=vn',
    en: 'hl=en&gl=us',
    ru: 'hl=ru&gl=ru',
    pt: 'hl=pt&gl=pt',
    ar: 'hl=ar&gl=sa',
    de: 'hl=de&gl=de',
    es: 'hl=es&gl=es',
    fr: 'hl=fr&gl=fr',
    kr: 'hl=ko&gl=kr',
    jp: 'hl=ja&gl=jp',
    id: 'hl=in_id',
  }
  return computed(() => {
    if (params.url) {
      return params.url
    }
    else {
      return `https://play.google.com/store/apps/details?id=${params.pkg}&${hlgl[locale.value as SupportLocale]}`
    }
  })
}

<script setup lang="ts">
import { NuxtLink } from '#components'

const userInput = ref('')

const { query, data, loading } = useSearch(userInput)

const currentIndex = ref(-1)

/** 键盘。上下键激活 */
function swichSelect(delta: number) {
  const length = unref(data).length
  if (!length)
    currentIndex.value = -1

  let next = currentIndex.value + delta
  const last = length - 1
  if (next > last)
    next = -1

  else if (next < -1)
    next = last

  currentIndex.value = next
}

/** 用于判断是否是搜索框聚焦 */
const searchFocus = ref(false)
const inputRef = ref<HTMLInputElement>()
const searchRef = ref<HTMLElement>()
// const resultRef = ref<HTMLElement>()
function hide() {
  searchFocus.value = false
}
function focus() {
  searchFocus.value = true
  inputRef.value?.focus()
}

onClickOutside(searchRef, hide)

const { toAppDetail, toSearch } = useRouterToPage()

/** 当用户点击搜索或者回车的时候 */
function handleClickOrEnter() {
  if (~currentIndex.value) {
    const item = data.value[currentIndex.value]

    /** 跳对应的详情页 */
    toAppDetail({ seo: item.seo, type: item.category })
  }
  else if (query.value) {
    toSearch(query.value)
  }
  hide()
}
</script>

<template>
  <div>
    <div
      ref="searchRef"
      class="group relative h-32px flex items-center of-hidden rd-4px bg-#ffffff1a p-8px text-16px [&>*]:transition-all-300"
      @click="focus"
    >
      <Icon
        name="Search"
        class="mis-4px c-#fff c-op-70"
        :class="searchFocus && 'c-op-100!'"
      />

      <input
        ref="inputRef" v-model="userInput"
        class="w-184px b-none bg-transparent px-12px text-16px c-current caret-purple-5 outline-0"
        type="text"
        aria-label="Search"
        @keydown.enter.prevent="handleClickOrEnter"
        @keydown.down.prevent="swichSelect(1)"
        @keydown.up.prevent="swichSelect(-1)"
      >

      <span class="mie-4px w-16px of-hidden">
        <Icon
          name="Close" class="cursor-pointer v-sub text-16px c-#fff transition-all-100 hover:c-op-90"
          :class="userInput ? 'op-100' : 'op-0 pointer-events-none'"
          @click="userInput = ''"
        />
      </span>
    </div>

    <template v-if="loading">
      <LdOptions
        class="pt-8px text-16px transition-all-300"
        :class="searchFocus && userInput ? 'w-240px op-100' : 'w-0 of-hidden op-0'"
        :list="Array(4).fill('')" :fold="false"
      >
        <template #item>
          <LdOptionsItem class="pointer-events-none">
            <div class="flex items-center gap-12px">
              <LdSkeleton shape="image" class="size-24px rd-4px" />
              <LdSkeleton class="h-1em flex-1" />
            </div>
          </LdOptionsItem>
        </template>
      </LdOptions>
    </template>

    <template v-else>
      <LdOptions
        v-if="data.length"
        class="pt-8px text-16px transition-all-300"
        :class="searchFocus && userInput ? 'w-240px op-100' : 'w-0 of-hidden op-0'"
        :list="data" :visible="!!(searchFocus && userInput)"
      >
        <template #item="{ item, index }">
          <LdOptionsItem :is="NuxtLink" v-track="{ id: 'web-layout-search-game', click: { value: { page: currentRouteName(), name: item.gameName, seo: item.seo, pkg: item.packageName } } }" :to="toAppDetail({ seo: item.seo, type: item.category }, { hasReturnUrl: true })" :active="currentIndex === index">
            <div class="w-208px flex items-center gap-12px">
              <LdImage class="size-24px rd-4px shadow-[0px_2px_10px_0px_#24248414]" :src="scaleImage(item.icon, 64)" :alt="item.gameName" loading="lazy" />
              <p class="truncate lh-20px">
                {{ item.gameName }}
              </p>
            </div>
          </LdOptionsItem>
        </template>
      </LdOptions>

      <div v-else class="relative mx-auto size-0">
        <div class="absolute translate-x--50% rtl:translate-x-50%">
          <div
            :class="searchFocus && userInput ? 'op-100 h-208px' : 'of-hidden op-0 h-0'"
            class="mt-8px w-240px flex-center flex-col rd-8px bg-#29283D"
          >
            <Icon class="h-68px w-128px" name="SearchNoneDark" />
            <p class="m-12px text-center text-14px c-#fff c-op-70 lh-24px">
              {{ $t('error.search_none') }}
            </p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
</style>

import type { RouteLocationRaw } from '#vue-router'
import { parseURL, stringifyParsedURL, stringifyQuery } from 'ufo'
import { type EasyfunGameJson, EasyfunGameType } from '~/types/easyfun'

type InitQueryKey = ['openid', 'final']

export interface HandleRouteOptions {
  /** 根据参数转成指定的默认值 */
  initQuery?: boolean | InitQueryKey[number][]
  /** 是否返回url */
  hasReturnUrl?: boolean
  /** 指定语言 */
  locale?: string
}

/**
 * 为了给一些需要穿query等参数的路由封装方法
 * eg: toAppDetail({seo:xxx, query: {n: 123}}, {hasReturnUrl: true})
 */
// type handleRouteLocation<T> = T & Omit<Location, 'name'>

/**
 * 路由跳转
 *
 * 是否返回url，如果返回url不会使用路由跳转
 *
 * 在中间件的时候需要用 useNuxtApp().$localePath 而不能用 useLocalePath
 */
export function useRouterToPage(inMiddleware: boolean = false) {
  const router = useRouter()
  const { queryData } = storeToRefs(useQueryStore())

  // const localePath = useLocalePath()
  // const { $localePath: localePath } = useNuxtApp()
  const localePath = inMiddleware ? useNuxtApp().$localePath : useLocalePath()

  function getInitQuery(status: HandleRouteOptions['initQuery'] = true) {
    const { openid, final } = unref(queryData)
    const query: Partial<Record<'n' | 'final', string>> = { n: openid !== '10000' ? openid : undefined, final }
    if (typeof status === 'boolean') {
      return status ? query : {}
    }
    if (!status.includes('openid') && query.n) {
      delete query.n
    }
    if (!status.includes('final') && query.final) {
      delete query.final
    }
    return query
  }

  /** 给url添加后缀 */
  function appendUrlSufix(url: string) {
    const initQuery = getInitQuery()

    const parsedURL = parseURL(url)
    parsedURL.search = stringifyQuery(initQuery)
    return stringifyParsedURL(parsedURL)
  }

  /** 默认直接跳转，默认携带参数（'openid', 'final'） */
  function handleRoute(route: Exclude<RouteLocationRaw, string>, options?: HandleRouteOptions): string {
    const initQuery = getInitQuery(options?.initQuery)

    // 对携带的参数带上默认参数
    route.query = { ...initQuery, ...route.query }

    const url = localePath(route, options?.locale)
    // 如果需要返回参数
    if (options?.hasReturnUrl) {
      return url
    }
    else {
      router.push(url)
      return ''
    }
  }

  /** 首页 */
  function toIndex(options?: HandleRouteOptions) {
    return handleRoute({ name: 'index' }, options)
  }

  /** 隐私政策 */
  function toPrivacy(options: HandleRouteOptions = { locale: 'en', hasReturnUrl: true }) {
    return handleRoute({ name: 'other-privacy.html' }, options)
  }

  /** GDPR 隐私声明 */
  function toGDPR(options: HandleRouteOptions = { locale: 'en', hasReturnUrl: true }) {
    return handleRoute({ name: 'other-gdpr.html' }, options)
  }

  /** 關於我們 */
  function toAboutUs(options?: HandleRouteOptions) {
    return handleRoute({ name: 'other-about.html' }, options)
  }

  /** easyfun 网页端 */
  function toEasyfunWeb(game: EasyfunGameJson, playNow = false) {
    const url = (isTest() || isDev()) ? 'https://pre-prod.easyfun.gg' : 'https://www.easyfun.gg'
    const typePrefix = game.gameType === EasyfunGameType.CLOUD ? 'games' : 'webgames'
    const query = playNow ? '?start=1' : ''
    return `${url}/${typePrefix}/${game.seo}.html${query}`
  }

  /** 搜索页 */
  function toSearch(q: string, options?: HandleRouteOptions) {
    return handleRoute({ name: 'search', query: { q } }, options)
  }

  /** 游戏中心 */
  function toAppCenter(options?: HandleRouteOptions) {
    // return handleRoute({ name: 'apps' }, options)
    return toIndex(options)
  }

  /** 游戏分类页 */
  function toAppType(type: 'upcoming-games' | number, options?: HandleRouteOptions) {
    return handleRoute({ name: 'apps-type.html', params: { type } }, options)
  }

  /** 游戏详情 */
  function toAppDetail(params: { seo: string, type: string }, options?: HandleRouteOptions) {
    params.type = params.type || 'other'
    return handleRoute({ name: 'apps-type-seo.html', params }, options)
  }

  /** 厂商游戏页 */
  function toDeveloper(name: string, options?: HandleRouteOptions) {
    return handleRoute({ name: 'developers-name.html', params: { name } }, options)
  }

  return {
    appendUrlSufix,
    handleRoute,

    toIndex,
    toPrivacy,
    toGDPR,
    toAboutUs,

    toEasyfunWeb,

    toSearch,

    toAppCenter,
    toAppType,
    toAppDetail,

    toDeveloper,
  }
}

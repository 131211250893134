<script lang="ts" setup>
interface Props {
  modelValue: boolean
  /** @default 100  */
  zIndex?: number

  /**
   * 是否允许通过点击蒙版图层关闭对话框
   * @default true
   */
  closeByMask?: boolean

  /**
   * use v-if, 关闭后销毁所有内部元件
   * @default true
   */
  useVIf?: boolean

  /**
   * 即使在其他视图中，也要保持对话框打开
   * @default false
   */
  keepAlive?: boolean

  /**
   * The aria-labelledby id for the dialog.
   */
  dialogLabelledBy?: string

  /**
   * 是否展示关闭按钮
   * @default true
   */
  showClose?: boolean

  /** 用于移动端特殊处理弹窗布局 */
  directionClass?: string
}

defineOptions({ inheritAttrs: false })

const props = withDefaults(defineProps<Props>(), {
  zIndex: 100,
  closeByMask: true,
  useVIf: true,
  keepAlive: false,
  showClose: false,
})

const emit = defineEmits<{
  /** v-model dialog visibility */
  (event: 'close'): void
  (event: 'update:modelValue', value: boolean): void
}>()

// 不知道为啥这里不兼容
// const visible = defineModel<boolean>({ required: true })
const visible = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)
  },
})

const deactivated = useDeactivated()
const route = useRoute()

/** scrollable HTML element */
const elDialogMain = ref<HTMLDivElement>()
const elDialogRoot = ref<HTMLDivElement>()

defineExpose({
  elDialogRoot,
  elDialogMain,
})

/**
 * ### Whether the current component is running in the background
 *
 * for handling problems caused by the keepalive function
 */
function useDeactivated() {
  const deactivated = ref(false)
  onActivated(() => deactivated.value = false)
  onDeactivated(() => deactivated.value = true)

  return deactivated
}

/** close the dialog */
function close() {
  if (!visible.value)
    return
  visible.value = false
  emit('close')
}

function clickMask() {
  if (props.closeByMask)
    close()
}

const routePath = ref(route.path)
watch(visible, (value) => {
  if (value)
    routePath.value = route.path
})

const notInCurrentPage = computed(() => deactivated.value || routePath.value !== route.path)
watch(notInCurrentPage, (value) => {
  if (props.keepAlive)
    return
  if (value)
    close()
})

// controls the state of v-if.
// when useVIf is toggled, v-if has the same state as modelValue, otherwise v-if is true
const isVIf = computed(() => {
  return props.useVIf
    ? visible.value
    : true
})

// controls the state of v-show.
// when useVIf is toggled, v-show is true, otherwise it has the same state as modelValue
const isVShow = computed(() => {
  return !props.useVIf
    ? visible.value
    : true
})

useEventListener('keydown', (e: KeyboardEvent) => {
  if (!visible.value)
    return
  if (e.key === 'Escape') {
    close()
    e.preventDefault()
  }
})
</script>

<template>
  <Teleport to="body">
    <!-- Dialog component -->
    <Transition name="dialog-visible">
      <div
        v-if="isVIf"
        v-show="isVShow"
        ref="elDialogRoot"
        aria-modal="true"
        :aria-labelledby="dialogLabelledBy"
        :style="{ 'z-index': zIndex }"
        class="fixed inset-0 of-y-auto overscroll-none [&::-webkit-scrollbar]:size-0px"
      >
        <!-- The style `overscroll-none of-y-scroll` and `h="[calc(100%+0.5px)]"` is used to implement scroll locking, -->
        <!-- 蒙层 -->
        <div class="dialog-mask absolute inset-0 z-0 touch-none bg-transparent op-100 backdrop-blur-sm backdrop-filter" />
        <!-- mask layer 背景 -->
        <div class="dialog-mask absolute inset-0 z-0 h-[calc(100%+0.5px)] touch-none bg-#0008" @click="clickMask" />
        <!-- Dialog container -->
        <div class="pointer-events-none absolute inset-0 z-1 flex op-100">
          <div class="flex flex-1 items-center justify-center" :class="directionClass">
            <div
              ref="elDialogMain"
              class="dialog-main pointer-events-auto isolate max-h-full touch-pan-x touch-pan-y of-y-auto overscroll-contain shadow-lg"
              :class="showClose && 'relative'"
              v-bind="$attrs"
            >
              <i v-if="showClose" class="absolute right-0 top-0 m-12px size-24px cursor-pointer c-#161636 c-op-30 hover:c-purple-5 hover:c-op-100" @click="close">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.65471 17.6546C4.26419 18.0451 4.26419 18.6783 4.65471 19.0688C5.04523 19.4593 5.6784 19.4593 6.06892 19.0688L11.7279 13.4098L17.387 19.0689C17.7775 19.4594 18.4107 19.4594 18.8012 19.0689C19.1917 18.6784 19.1917 18.0452 18.8012 17.6547L13.1421 11.9956L18.7968 6.3409C19.1874 5.95037 19.1874 5.31721 18.7968 4.92668C18.4063 4.53616 17.7732 4.53616 17.3826 4.92668L11.7279 10.5814L6.07327 4.92678C5.68275 4.53625 5.04958 4.53625 4.65906 4.92678C4.26853 5.3173 4.26854 5.95046 4.65906 6.34099L10.3137 11.9956L4.65471 17.6546Z" fill="currentColor" />
                </svg>

              </i>
              <slot />
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style lang="scss" scoped>
.dialog-visible-enter-active,
.dialog-visible-leave-active {
  transition-duration: 0.25s;

  .dialog-mask {
    transition: opacity 0.25s ease;
  }

  .dialog-main {
    transition:
      opacity 0.25s ease,
      transform 0.25s ease;
  }
}

.dialog-visible-enter-from,
.dialog-visible-leave-to {
  .dialog-mask {
    opacity: 0;
  }

  .dialog-main {
    transform: translateY(50px);
    opacity: 0;
  }
}
</style>

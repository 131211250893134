<script setup lang="ts">
import { useLazyload } from 'vue3-lazyload'

interface Props {
  src: string | null
  loading?: 'lazy' | 'eager'
  skeletonClass?: string
}

const props = defineProps<Props>()
const src = computed(() => props.src!)
const isLazy = computed(() => props.loading === 'lazy')

const loadFinised = ref(!isLazy.value)
const loadError = ref(false)

const lazyRef = isLazy.value && src.value
  ? useLazyload(src, {
    lifecycle: {
      loading: () => {
        loadFinised.value = false
      },
      error: () => {
        loadFinised.value = false
        loadError.value = true
      },
      loaded: () => {
        loadFinised.value = true
      },
    },
  })
  : ref()
</script>

<template>
  <LdSkeleton v-if="!props.src || loadError" />
  <img v-else ref="lazyRef" :data-src="src" :class="!loadFinised && [skeletonClass, 'bg-#bbb3']" :src="!isLazy ? props.src : ''">
</template>

<style lang="scss" scoped>
</style>

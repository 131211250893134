import type { AppDetailPage, AppSimilar, HomePage, SemDetails } from '~/types'
import type { Page, Pagination } from '~/types/base'
import type { AppSearchLite } from '~/types/search'

enum Api {
  CHECK_IP_AREA = '/ip/checkIpArea', // 获取ip所在地区码
  HOME_PAGE = '/ldplayerX/home/collect', // 首页接口
  APP_DETAIL = '/ldplayerX/home/detail', // 应用详情
  APP_TYPES = '/ldplayerX/home/category', // 游戏类型列表

  FUZZY_SEARCH = '/ldplayerX/home/retrieve', // 模糊搜索
  APP_SEARCH = '/ldplayerX/home/search', // 搜索列表

  DEVELOPER_APPS = '/ldplayerX/home/manufacturer', // 开发者游戏列表

  SEM_DETAILS = '/landing/ldx/getSemPage', // 落地页详情

  ARTICLE_DETAIL = '/ldplayerX/home/article', // 文章详情
}

/**
 * 获取ip所在地区码
 *
 * @returns {string} 返回对应ip的地区 如果是us/gb 则返回en
 */
export async function getIpAreaApi() {
  let ip = await useHttp.get<string>(Api.CHECK_IP_AREA)
  ip = ip.toLowerCase()
  return ip === 'us' || ip === 'gb' ? 'en' : ip
}

/** 首页接口 */
export function getHomePageApi() {
  const { refreshJson } = useEasyfunGameJson()
  return Promise.all([useHttp.get<HomePage>(Api.HOME_PAGE), refreshJson()])
}

/** 应用详情 */
export function getAppDetailApi(body: { category: string, seo?: string, gameId?: number }) {
  return useHttp.post<AppDetailPage>(Api.APP_DETAIL, { body })
}

/** 应用类型 类型如果穿 upcoming-games 特殊处理 */
export function getAppsByTypeApi(type: number | string, page: Page) {
  let body
  if (type === 'upcoming-games') {
    body = { page, isPreRegister: 1 }
  }
  else {
    body = { page, type }
  }
  return useHttp.post<{ games: Pagination<AppSimilar>, typeName: string }>(Api.APP_TYPES, { body })
}

/**  模糊搜索 */
export function getFuzzySearchApi(wanna: string) {
  return useHttp.get<AppSearchLite[]>(Api.FUZZY_SEARCH, { params: { wanna } })
}

/**  搜索列表 */
export function getAppBySearchApi(name: string) {
  return useHttp.post<AppSearchLite[]>(Api.APP_SEARCH, { query: { name } })
}

/** 开发者游戏列表 */
export function getAppByDeveloperApi(name: string) {
  return useHttp.get<AppSimilar[]>(`${Api.DEVELOPER_APPS}/${name}`)
}
/** 落地页详情 */
export function getSemDetails(name: string) {
  return useHttp.get<SemDetails>(Api.SEM_DETAILS, { params: { name } })
}

/** 文章详情 */
export function getArticleDetail(seo: string) {
  return useHttp.get<any>(`${Api.ARTICLE_DETAIL}/${seo}`)
}

import type { MaybeComputedElementRef, MaybeElement } from '@vueuse/core'
import type { SupportLocale } from '~/config/i18n'
import type { LogEventKey, LogEventParams } from '~/types/log'

/** 日志上报 */
export function useLogReport() {
  return {
    reportEvent,

    searchReport,
    clickReport,
    viewReport,
    exposureReport,

    elementExposure,
  }
}

/**  日志上报 */
export function reportLogApi({ locale, ...body }: LogEventParams) {
  const { public: { apiLog } } = useRuntimeConfig()
  const baseURL = apiLog.replace('{locale}', locale!)

  const blob = new Blob([JSON.stringify(body)], {
    type: 'application/json; charset=UTF-8',
  })

  return navigator.sendBeacon(`${baseURL}/assistantEvent`, blob)
}

/** 上报逻辑 */
async function reportEvent({ locale, ...params }: Omit<LogEventParams, 'macAdr' | 'platform' | 'pname' | 'version'>) {
  const { $i18n: { locale: defaltLocale } } = useNuxtApp()
  const { queryData } = useQueryStore()
  const { openid } = queryData

  // 需要等待UUID生成
  const { sdkInit } = useUserInfo()
  await sdkInit.value

  let UUID = localStorage.getItem('LDSDK::SID') || localStorage.getItem('LDSDK::SID_FAKE')
  if (!UUID) {
    UUID = `${getRandomString(16)}___`
    localStorage.setItem('LDSDK::SID_FAKE', UUID)
  }

  const query: LogEventParams = {
    ...params,
    version: 'unknow',
    locale: (locale ?? defaltLocale.value) as SupportLocale,
    macAdr: UUID,
    platform: openid,
    pname: 'ldplayerX-web',
  }

  reportLogApi(query)

  const GTM_EVENT = `custom_${query.eventType}`
  const PARSE_PARAMS = JSON.parse(query.eventValue)
  // gtm上报
  window?.dataLayer?.push({ event: GTM_EVENT, ...PARSE_PARAMS, ...query })
}

/** 搜索事件 */
function searchReport(value: string) {
  return reportEvent({
    eventType: 'search',
    eventKey: 'web-layout-search',
    eventValue: JSON.stringify({ value }),
  })
}

/** 点击事件 */
function clickReport(key: LogEventKey, params: { value: string, locale?: SupportLocale }) {
  return reportEvent({
    eventType: 'click',
    eventKey: key,
    eventValue: params.value,
    locale: params.locale,
  })
}

/** 曝光事件 */
function exposureReport(key: LogEventKey, params: { value: string, locale?: SupportLocale }) {
  return reportEvent({
    eventType: 'exposure',
    eventKey: key,
    eventValue: params.value,
    locale: params.locale,
  })
}

/** 浏览事件 */
function viewReport(key: LogEventKey, params: { value: string, locale?: SupportLocale }) {
  return reportEvent({
    eventType: 'view',
    eventKey: key,
    eventValue: params.value,
    locale: params.locale,
  })
}

/**
 * 封装暴露逻辑 曝光500ms后上报
 * @param {MaybeComputedElementRef<MaybeElement>} el dom
 * @param {() => any} cb exposure callback
 * @param {{ delay: number }} conf delay = 500
 */
function elementExposure(el: MaybeComputedElementRef<MaybeElement>, cb: () => any, conf?: { delay: number }) {
  const DELAY = conf?.delay ?? 500
  let timeout: ReturnType<typeof setTimeout> | undefined

  function clear() {
    if (timeout) {
      clearTimeout(timeout)
      timeout = undefined
    }
  }

  function start() {
    timeout = setTimeout(cb, DELAY)
  }

  const isExposureed = useElementVisibility(el, {
    threshold: 0.8,
  })

  watch(isExposureed, v => v ? start() : clear())
}

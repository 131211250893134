<script lang="ts" setup>
const { languageLoading } = useLanguageStore()
const { languageList } = storeToRefs(useLanguageStore())

const route = useRoute()
const routeBaseName = useRouteBaseName()
// 阻塞渲染，为了解决从上往下渲染导致的 head 无法获取正确连接问题
// , 'index'
if (['apps-type-seo.html'].includes(routeBaseName(route) || '')) {
  onServerPrefetch(() => languageLoading.promise)
}

const { appendUrlSufix } = useRouterToPage()
</script>

<template>
  <LdDropdown class="text-16px" :list="languageList">
    <Icon name="Earth" />
    <span class="ml-10px mr-6px font-700">{{ $t('Language.language_name') }}</span>

    <template #item="{ item }">
      <LdOptionsItem is="a" v-track="{ id: 'web-layout-language', click: { value: { page: currentRouteName(), from: $i18n.locale, to: item.code } } }" :href="appendUrlSufix(item.domain!)" target="_blank" :title="item.title">
        {{ item.name }}
      </LdOptionsItem>
    </template>
  </LdDropdown>
</template>

<style lang="scss" scoped>
</style>
